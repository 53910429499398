import { useState } from "react";
import { ResponsiveHoneycomb, Hexagon } from "react-honeycomb";

const Projects = () => {
  const projectList = [
    { name: "CHR Adapter Service", company: "Ox" },
    { name: "Private API", company: "Ox" },
    { name: "API JS SDK", company: "Ox" },
    { name: "Fulfillment App", company: "Ox" },
    { name: "Domain Module", company: "Ox" },
    { name: "AMP+", company: "Walmart" },
    { name: "AMP+ API", company: "Walmart" },
    { name: "Pickup Display", company: "Walmart" },
    { name: "Sumo Platform", company: "Walmart" },
    { name: "Hermes Platform", company: "Tinted-Technologies" },
    { name: "Tap Banking", company: "Tinted-Technologies" },
    { name: "Baptist Health App", company: "Doc4" },
    { name: "Bentonville Chamber Of Commerce Site", company: "Doc4" },
    { name: "Fishing Co Website", company: "Doc4" },
  ];

  //   TODO: Add Company Filter select
  const [currentProjectList, setCurrentProjectList] = useState(projectList);

  const companyColors = {
    Ox: "bg-neutral-800 hover:bg-neutral-600",
    Walmart: "bg-blue-500 hover:bg-blue-700",
    Doc4: "bg-emerald-700 hover:bg-emerald-500",
  };

  return (
    <div className="p-10">
      <ResponsiveHoneycomb
        defaultWidth={1024}
        size={150}
        items={currentProjectList}
        renderItem={(item) => (
          <Hexagon
            className={`${
              companyColors[item.company] ?? "bg-gray-500 hover:bg-gray-700"
            } flex flex-col cursor-pointer`}
          >
            <div className="m-auto text-white text-3xl pl-2 pr-2">
              {item.name}
              <br />
              <div className="border-t-2 pt-1 mt-1 border-white">
                {item.company}
              </div>
            </div>
          </Hexagon>
        )}
      />
    </div>
  );
};

export default Projects;
