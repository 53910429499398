import { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";

const NotFound = () => {
  const [disableProgress, setDisableProgress] = useState(true);
  const [disableHomeButton, setDisableHomeButton] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      setDisableProgress(false);
    }, 6000);
    return () => {
      clearTimeout(timeoutRef);
    };
  }, []);

  useEffect(() => {
    if (!disableProgress && disableHomeButton) {
      const intervalRef = setInterval(() => {
        setProgress((progress) => {
          if (progress === 99) {
            clearInterval(intervalRef);
            setDisableHomeButton(false);
          }
          return progress + 1;
        });
      }, 50);
    }
  }, [disableProgress]);

  return (
    <div>
      <h1 className="text-5xl mt-10">404 - Not Found!</h1>

      <div className="pl-20 pr-20 pt-10 text-lg">
        Looks like you landed on a non-existent page, It happens to the best of
        us. Let me get this fixed for you
      </div>

      {!disableProgress && disableHomeButton && (
        <div className="p-10">
          <div className="mb-3">Progress: {progress}%</div>
          <div className="w-full bg-gray-200 h-5 mb-6 rounded-full">
            <div
              className="bg-gradient-to-r from-red-500 via-yellow-500 to-green-500 h-5 rounded-full"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      )}
      {!disableHomeButton && (
        <div className="mt-10">
          <Link
            className="bg-blue-500 hover:bg-blue-700 text-xl text-white font-bold py-2 px-4 rounded-full"
            to={"/"}
          >
            Go Home
          </Link>
        </div>
      )}
    </div>
  );
};

export default NotFound;
