import Typewriter from "typewriter-effect";
import Background from "../assets/picture_of_ox_friends.JPG";

const Home = () => {
  return (
    <div>
      <div className="flex justify-around mt-40">
        <div className="w-80 flex flex-col">
          <div className="m-auto">
            <Typewriter
              options={{
                wrapperClassName: "text-5xl",
                cursorClassName: "text-5xl",
                strings: [
                  "Hi, my name is Skyler Fredrickson",
                  "Full Stack Developer",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </div>
        </div>
        <div>
          <img
            className="rounded-lg drop-shadow-xl"
            height={300}
            width={300}
            src={Background}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
