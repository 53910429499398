import ProfilePicture from "../assets/IMG_0901.JPG";
import moment from "moment";
import { useEffect, useState } from "react";

const About = () => {
  const calculateDuration = () => {
    return moment.duration(
      moment().diff(moment("4/14/2001 11:58:00").format("M/DD/YYYY hh:mm:ss"))
    );
  };

  const [ageDuration, setAgeDuration] = useState(calculateDuration());

  const content = [
    {
      title: "Early On:",
      content: `I decided at an early age that I wanted to pursue computers and
      engineering. For as long as I can remember, I've been interested in
      the idea of automating everything. Goals, finance, doing laundry,
      all the little things that take your focus away from doing what you
      ACTUALLY want to do, they needed to be automated. This mentality is
      what has driven every action and big decision i have made so far in
      my life.`,
    },
    {
      title: "High school:",
      content: `This period of my life was a time of discovery and the first iteration
      of difficult decisions. From switching from traditional classes to Ignite, 
      quitting Wrestling to further my efforts toward Software Development, Giving up summer
      to work a corporate internship, many sacrifices were made to ensure the pieces of my life
      that I believed would make the most impact would flourish and continue to grow. Through this
      lifestyle I have outgrown many relationships and friends and been put in high stress situations
      for my age. This being said, this is what I desire. This is what I've been working for.
      My want is to be the most successful version of myself.`,
    },
    {
      title: "Current:",
      content: `After leaving Walmart due to promises not being upheld, 
      joining small start-ups and having to be a one man team, I've finally landed with a company
      that meets the criteria of my mission, Ox. Ill go into their details specifically on their own page.
      My current endeavors outside of work come from a book called "Atomic Habits". This book emphasis's
      the importance of systems rather than goals. This new outlook on life and how to achieve has me hyper
      focused on improving the "System" my life runs on rather than the goals im trying to achieve. This is
      because a goal can be met through bad means but a good system will always output (in time) the desired
      results regardless. The key point being that the underlying system being altered will continue to produce
      the desired results. Goals come and go.`,
    },
  ];

  useEffect(() => {
    const intervalRef = setInterval(() => {
      setAgeDuration(calculateDuration());
    }, 1000);
    return () => {
      clearInterval(intervalRef);
    };
  }, []);

  return (
    <div className="flex flex-col justify-center pb-10">
      {/* Top Card */}
      <div className="flex flex-col md:flex-row p-10 rounded overflow-hidden shadow-lg ml-10 mr-10 mt-5 mb-5 bg-slate-100">
        <div>
          <img className="rounded" width={400} src={ProfilePicture} />
        </div>
        <div className="flex flex-col w-1/2 mt-5 md:ml-5 justify-around text-left">
          {/* Specific about me details */}
          <div>
            <div className="text-xl font-normal leading-normal mt-0 mb-2 text-blueGray-800">
              Name: <div className="text-lg">Skyler Fredrickson</div>
            </div>
            <div className="text-xl font-normal leading-normal mt-0 mb-2 text-blueGray-800">
              Age:
              <div className="text-lg">
                {!ageDuration ? (
                  "loading..."
                ) : (
                  <>
                    {ageDuration.years()} Years, {ageDuration.months()} Months,{" "}
                    {ageDuration.days()} Days, {ageDuration.hours()} Hours,{" "}
                    {ageDuration.minutes()} Minutes, {ageDuration.seconds()}{" "}
                    Seconds
                  </>
                )}
              </div>
            </div>
            <div className=" text-xl font-normal leading-normal mt-0 mb-2 text-blueGray-800">
              Interests:
              <div className="flex flex-wrap">
                <div className="text-lg rounded-full p-3 m-auto  bg-amber-600 text-center text-white">
                  Reading
                </div>
                <div className="text-lg rounded-full p-3 m-auto  bg-yellow-600 text-center text-white">
                  Working Out
                </div>
                <div className="text-lg rounded-full p-3 m-auto  bg-red-600 text-center text-white">
                  Hanging with Friends
                </div>
                <div className="text-lg rounded-full p-3 m-auto  bg-blue-600 text-center text-white">
                  Learning
                </div>
                <div className="text-lg rounded-full p-3 m-auto  bg-green-600 text-center text-white">
                  Finance
                </div>
                <div className="text-lg rounded-full p-3 m-auto  bg-purple-600 text-center text-white">
                  Philosophical Conversations
                </div>
              </div>
            </div>
            <ul className="ml-5 flex flex-wrap mt-3"></ul>
          </div>
          <div className="text-xl font-normal leading-normal mt-0 mb-2 text-blueGray-800">
            Fun fact:
            <div className="text-lg">
              I have a condition called Waardenburg Syndrome, which causes me to
              have a white birthmark in my hair and different colored eyes
            </div>
          </div>
          <div className="text-xl font-normal leading-normal mt-0 mb-2 text-blueGray-800">
            Contact Info:
            <div className="text-lg">Email: skylerfredric19@gmail.com</div>
          </div>
        </div>
      </div>
      <div className="underdivne text-2xl">My Journey</div>
      {content.map((div) => (
        <div className="flex justify-center">
          <div className="w-3/4 pl-5 pr-5">
            <div className="text-lg mt-4">{div.title}</div>
            <p className="text-left">{div.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default About;
